import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as Yup from "yup";
import { FC } from "react";
import http from "src/utils/httpHelper";
import { useSnackbar } from "notistack";
import anyToString from "src/utils/anyToString";

interface ChangePasswordDialogProps {
    isOpen: boolean,
    onClose: any
}

const ChangePasswordDialog: FC<ChangePasswordDialogProps> = ({ isOpen, onClose }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    return (
        <>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={isOpen}
                onClose={onClose}
            >
                <DialogTitle
                    sx={{
                        p: 3,
                    }}
                >
                    <Typography variant="h4" gutterBottom>
                        {t("MY_ACCOUNT.CHANGE_PASSWORD_HEADING")}
                    </Typography>
                    <Typography variant="subtitle2">
                        {t("MY_ACCOUNT.CHANGE_PASSWORD_SUBHEADING")}
                    </Typography>
                </DialogTitle>
                <Formik
                    initialValues={{
                        current_password: "",
                        password: "",
                        password_confirmation: "",
                        submit: null,
                    }}
                    validationSchema={Yup.object().shape({
                        current_password: Yup.string()
                            .max(255)
                            .required(t("VALIDATION.REQUIRED")),
                        password: Yup.string()
                            .max(255)
                            .required(t("VALIDATION.REQUIRED")),
                        password_confirmation: Yup.string()
                            .max(255)
                            .required(t("VALIDATION.REQUIRED")),
                    })}
                    onSubmit={async (
                        _values,
                        { resetForm, setErrors, setStatus, setSubmitting }
                    ) => {
                        await http.put('/user/password', _values)
                            .then(
                                (response: any) => {
                                    enqueueSnackbar(response.message, { variant: 'success' });
                                    resetForm();
                                    setStatus({ success: true });
                                })
                            .catch((error: any) => {
                                enqueueSnackbar(error.message, { variant: 'error' });
                                setStatus({ success: false });
                                setErrors({ submit: error.message });
                            })
                            .finally(() => {
                                setSubmitting(false);
                            })
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <DialogContent
                                dividers
                                sx={{
                                    p: 3,
                                }}
                            >
                                <Grid container spacing={3}>
                                    <Grid item xs={12} lg={12}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} lg={12}>
                                                <TextField
                                                    error={Boolean(touched.current_password && errors.current_password)}
                                                    fullWidth
                                                    helperText={touched.current_password && errors.current_password && anyToString(errors.current_password)}
                                                    label={t("FIELDS.CURRENT_PASSWORD")}
                                                    name="current_password"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.current_password}
                                                    variant="outlined"
                                                    type={'password'}
                                                />
                                            </Grid>
                                            <Grid item xs={12} lg={12}>
                                                <TextField
                                                    error={Boolean(
                                                        touched.password && errors.password
                                                    )}
                                                    fullWidth
                                                    helperText={touched.password && errors.password && anyToString(errors.password)}
                                                    label={t("FIELDS.PASSWORD")}
                                                    name="password"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.password}
                                                    variant="outlined"
                                                    type={'password'}
                                                />
                                            </Grid>
                                            <Grid item xs={12} lg={12}>
                                                <TextField
                                                    error={Boolean(
                                                        touched.password_confirmation && errors.password_confirmation
                                                    )}
                                                    fullWidth
                                                    helperText={touched.password_confirmation && errors.password_confirmation && anyToString(errors.password_confirmation)}
                                                    label={t("FIELDS.PASSWORD_CONFIRMATION")}
                                                    name="password_confirmation"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.password_confirmation}
                                                    variant="outlined"
                                                    type={'password'}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions
                                sx={{
                                    p: 3,
                                }}
                            >
                                <Button color="secondary" onClick={onClose}>
                                    {t("Cancel")}
                                </Button>
                                <Button
                                    type="submit"
                                    startIcon={
                                        isSubmitting ? <CircularProgress size="1rem" /> : null
                                    }
                                    disabled={Boolean(errors.submit) || isSubmitting}
                                    variant="contained"
                                >
                                    {t("MY_ACCOUNT.BTN_CHANGE_PASSWORD")}
                                </Button>
                            </DialogActions>
                        </form>
                    )}
                </Formik>
            </Dialog>
        </>
    )
}
export default ChangePasswordDialog;