import React, { useState } from "react";
import { pink } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { Typography, Box, Divider, Step, StepLabel, Stepper, Stack, Alert, CircularProgress, Container } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Button, { ButtonProps } from '@mui/material/Button';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import 'src/assets/css/custom.css';
import FormOne from "./FormOne";
import FormTwo from "./FormTwo";
import Final from "./Final";
import PrintIcon from '@mui/icons-material/Print';
import http, { ROUTES } from "src/utils/httpHelper";
import Grid from '@mui/material/Grid'; // Grid version 1
import { useNavigate } from "react-router-dom";
import Block1 from 'src/components/Stepper/Texts/Block1';
import Block2 from 'src/components/Stepper/Texts/Block2';
import Block3 from 'src/components/Stepper/Texts/Block3';
import Block4 from 'src/components/Stepper/Texts/Block4';
import Block5 from 'src/components/Stepper/Texts/Block5';
import Block6 from 'src/components/Stepper/Texts/Block6';
import Block7 from 'src/components/Stepper/Texts/Block7';
import Block8 from 'src/components/Stepper/Texts/Block8';
import { useForm } from 'react-hook-form';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { parse } from "path";

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText(pink[600]),
  backgroundColor: pink[600],
  '&:hover': {
    backgroundColor: pink[700],
  },
}));

function getSteps() {
  return ["1", "2", "3"];
}

function getStepContent(control, generalDataController, step) {
  switch (step) {
    case 0:
      return <FormOne control={control} generalDataController={generalDataController} />;
    case 1:
      return <FormTwo control={control} generalDataController={generalDataController} />;
    case 2:
      return <Final control={control} generalDataController={generalDataController} />;
    default:
      return "Unknown step";
  }
}

export default function Main(props) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();
  const [isLoading, setIsLoading] = useState(false);
  let navigate = useNavigate(); 

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const next = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  }

  const handleNext = async () => {
    switch (activeStep) {  
      case 0:
        let stepOneRequiredPassed = await props.generalDataController.trigger([
          'formality_id',
          'licence_type_id',
          'module_id',
          'appointment_date',
          'appointment_time',
        ]);

        if (!stepOneRequiredPassed) {
          return;
        }

        setIsLoading(true);
        http.post(ROUTES.GENERATE_APPOINTMENT, {
          formality_id: props.generalDataController.getValues('formality_id'),
          licence_type_id: props.generalDataController.getValues('licence_type_id'),
          module_id: props.generalDataController.getValues('module_id'),
          appointment_date: props.generalDataController.getValues('appointment_date'),
          appointment_time: props.generalDataController.getValues('appointment_time')
        })
          .then((response: any) => {
            props.generalDataController.setValue('id', response.data.id);
            showTimmer();
            props.generalDataController.countdownComponent.current.start();
            next();
          })
          .catch(error => {
            props.generalDataController.enqueueSnackbar(
              error.message != '' ? error.message :
                'Hubo un error al apartar la cita.\n' + error.exception,
              { variant: 'error' });
          })
          .finally(() => {
            setIsLoading(false);
          })
        break;
      case 1:
        let stepTwoRequiredPassed = await props.generalDataController.trigger([
          'licences_year_id',
          'curp',
          'name',
          'last_name',
          'second_last_name',
          'sex',
          'rfc',
          'birthdate',
          'marital_status',
          'nationality_id',
          'phone',
          'email',
          'occupation',
        ]);

        if (!stepTwoRequiredPassed) {
          return;
        }

        next();
        break;
      case 2:
        let finalStepRequiredPassed = await props.generalDataController.trigger();
        if (!finalStepRequiredPassed) {
          return;
        }
        let formData = props.generalDataController.getValues();
        setIsLoading(true);
        http.post(ROUTES.GENERATE_APPOINTMENT, formData)
          .then((response: any) => {
            next();
          })
          .catch(error => {
            props.generalDataController.enqueueSnackbar(
              error.message != '' ? error.message :
                'Hubo un error al generar la cita.\n' + error.exception,
              { variant: 'error' });
          })
          .finally(() => {
            setIsLoading(false);
          })
        break;

      default:
        break;
    }
  };

  const showTimmer = () => {
    props.generalDataController.setShowTimer(true);
  }

  const hideTimmer = () => {
    props.generalDataController.setShowTimer(false);
  }

  const handleBack = () => {
    if(activeStep == 0){
      navigate("/");
    }else{
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const print = () => {
    window.print();
  }

  const { watch} = useForm();
  const watchFormalityId = watch("formality_id");

  const documentation = () => {
    console.log("holo como tas");
    console.log("Fuera de");
    console.log(props.generalDataController.getValues('formality_id'))
    console.log(watchFormalityId);
    console.log("/Fuera de");
    switch(parseInt(props.generalDataController.getValues('formality_id'))){
      case 1:
        console.log(1);
        return <Container><Block1 /></Container>;
        break;
      case 2:
        console.log(2);
        return <Container><Block2 /></Container>;
        break;
      case 3:
        console.log(3);
        return <Container><Block3 /></Container>;
        break;
      case 4:
        console.log(4);
        return <Container><Block4 /></Container>;
        break;
      case 5:
        console.log(5);
        return <Container><Block5 /></Container>;
        break;
      case 6:
        console.log(6);
        return <Container><Block6 /></Container>;
        break;
      case 7:
        console.log(7);
        return <Container><Block7 /></Container>;
        break;
      case 8:
        console.log(8);
        return <Container><Block8 /></Container>;
        break;
      }
  }

  return (
    <>
    {activeStep === steps.length ? hideTimmer() : ''}
      <div>
        <Stepper activeStep={activeStep}>
          {steps.map((label) => {
            const stepProps = {};
            const labelProps = {};

            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div>
          {activeStep === steps.length ? (     
            <div>
              <Stack style={{ width: "100%", alignItems: "center", display: "flex" }} sx={{ mt: 2 }} spacing={2}>
                <Card sx={{ minWidth: 275 }}>
                  <CardContent>
                    <Alert iconMapping={{ success: <CheckCircleOutlineIcon sx={{ fontSize: "50px" }} />, }} >
                      <Typography variant="h2" sx={{ lineHeight: "unset" }}>Listo! tu cita ha quedado agendada</Typography>
                    </Alert>
                  </CardContent>
                </Card>

                <Card sx={{ minWidth: 275 }}>
                  <CardContent>
                    <Grid container >
                      <Grid xs={12} md={6}>
                        <Typography variant="button" display="block" gutterBottom>
                          Folio:
                        </Typography>
                        {props.generalDataController.getValues('id')}
                      </Grid>
                      <Grid xs={12} md={6}>
                        <Typography variant="button" display="block" gutterBottom>
                          Cita:
                        </Typography>
                        {props.generalDataController.getValues('appointment_date')} &nbsp;
                        {props.generalDataController.getValues('appointment_time')} Hrs 
                      </Grid>
                      <Grid xs={12} md={6}>
                        <Typography variant="overline" display="block" gutterBottom>
                          Tipo de trámite:
                        </Typography>   
                        {props.generalDataController.getValues('formality_name')}
                      </Grid>
                      <Grid xs={12} md={6}>
                        <Typography variant="overline" display="block" gutterBottom>
                          Modulo:
                        </Typography>    
                        {props.generalDataController.selectsData.modules.find(nationalities => nationalities.id == props.generalDataController.getValues('module_id'))?.name}
                      </Grid>
                      <Grid xs={12} md={6}>
                        <Typography variant="overline" display="block" gutterBottom>
                          Tipo de licencia:
                        </Typography>  
                        {props.generalDataController.selectsData.licenceTypes.find(nationalities => nationalities.id == props.generalDataController.getValues('formality_id'))?.name}
                      </Grid>
                      <Grid xs={12} md={6}>
                        <Typography variant="overline" display="block" gutterBottom>
                          Costo de la licencia:
                        </Typography>                    
                        $ {props.generalDataController.amount} MxN
                      </Grid>
                    </Grid>
                    <Button 
                      variant="outlined" 
                      startIcon={<PrintIcon />} 
                      fullWidth 
                      sx={{ mt: 2 }}
                      onClick={ print }
                      >Imprimir</Button>
                  </CardContent>
                </Card>                
                
                {documentation()}

                <Button href="/info">Volver al inicio</Button>
              </Stack>
            </div> 
          ) : (
            <div>
              <Typography>
                {getStepContent(props.control, props.generalDataController, activeStep)}
              </Typography>

              <Divider sx={{ mt: 2, mb: 2 }}></Divider>

              <Box>
                <Button
                  onClick={handleBack}
                  color="secondary"
                  variant="outlined"
                >
                  <ChevronLeftIcon />
                  Regresar
                </Button>

                <ColorButton
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  style={{ float: "right" }}
                  disabled={isLoading}
                  startIcon={
                    isLoading ? <CircularProgress size="1rem" /> : null
                  }
                >
                  {activeStep === steps.length - 1 ? "Terminar" : "Siguiente"}
                  <ChevronRightIcon />
                </ColorButton>
              </Box>
            </div>
          )}
        </div>
      </div>
    </>
  );
}