import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from "react-helmet-async";
import { Container, Box, Divider, Chip, Grid, Alert, Typography } from "@mui/material";
import Stepper from 'src/components/Stepper';
import ReactDOM from "react-dom";
import Countdown from "react-countdown";
import 'src/assets/css/custom.css';
import TimerIcon from '@mui/icons-material/Timer';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { pink } from '@mui/material/colors';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import i18n from 'src/i18n/i18n';
import * as Yup from "yup";
import http, { ROUTES } from 'src/utils/httpHelper';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { useSnackbar } from 'notistack';

// Completion
const Completionist = () => AlertDialog();

// Renderer callback with condition
const renderer = ({ minutes, seconds, completed }) => {
  if (completed) {
    // Render a complete state
    return <Completionist />;
  } else {
    // Render a countdown
    return (
      <span>
        {minutes}:{seconds}
      </span>
    );
  }
};

// Completion Dialog
function AlertDialog() {
  return (
    <Dialog open={true} PaperProps={{ elevation: 1 }}>
      <DialogTitle>
        <Typography sx={{ mt: 2 }} variant="h3" style={{ fontWeight: "bold", display: 'flex', alignItems: 'center' }}>
          <TimerIcon />
          ¡Tiempo agotado!
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h4" style={{ fontWeight: 500, display: 'flex', alignItems: 'center' }}>
          Se agotó el tiempo disponible para agendar tu cita. Porfavor, inténtalo de nuevo.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button href="/info" sx={{ color: pink[600] }} autoFocus>
          Volver al inicio
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const validationSchema = Yup.object().shape({
  formality_id: Yup.string()
    .required(i18n.t("VALIDATION.REQUIRED")),
  licence_type_id: Yup.string()
    .required(i18n.t("VALIDATION.REQUIRED")),
  module_id: Yup.string()
    .required(i18n.t("VALIDATION.REQUIRED")),
  appointment_date: Yup.date()
    .nullable()
    .transform((curr, orig) => orig === '' ? null : curr)
    .required(i18n.t("VALIDATION.REQUIRED")),
  appointment_time: Yup.string()
    .required(i18n.t("VALIDATION.REQUIRED")),

  licences_year_id: Yup.string()
    .required(i18n.t("VALIDATION.REQUIRED")),
  curp: Yup.string()
    .min(18, "CURP no válida")
    .max(18, "CURP no válida")
    .required(i18n.t("VALIDATION.REQUIRED")),
  name: Yup.string()
    .required(i18n.t("VALIDATION.REQUIRED")),
  last_name: Yup.string()
    .required(i18n.t("VALIDATION.REQUIRED")),
  second_last_name: Yup.string(),
  sex: Yup.string()
    .required(i18n.t("VALIDATION.REQUIRED")),
  rfc: Yup.string()
    .required(i18n.t("VALIDATION.REQUIRED"))
    .min(10, "Colocar RFC válido")
    .max(13, "Colocar RFC válido"),
  birthdate: Yup.date()
    .typeError('Fecha no válida')
    .nullable()
    .transform((curr, orig) => orig === '' ? null : curr)
    .required(i18n.t("VALIDATION.REQUIRED")),
  marital_status: Yup.string()
    .required(i18n.t("VALIDATION.REQUIRED")),
  nationality_id: Yup.string()
    .required(i18n.t("VALIDATION.REQUIRED")),
  phone: Yup.string()
    .nullable()
    .transform((curr, orig) => orig === '' ? null : curr)
    .min(10, "Colocar un número telefónico válido")
    .max(10, "Colocar un número telefónico válido")
    .required(i18n.t("VALIDATION.REQUIRED")),
  email: Yup.string()
    .email(i18n.t('VALIDATION.EMAIL'))
    .required(i18n.t("VALIDATION.REQUIRED")),
  occupation: Yup.string()
    .required(i18n.t("VALIDATION.REQUIRED")),

  prefix: Yup.string()
    .required(i18n.t("VALIDATION.REQUIRED")),
  street: Yup.string()
    .required(i18n.t("VALIDATION.REQUIRED")),
  int_number: Yup.number()
    .nullable()
    .transform((curr, orig) => orig === '' ? null : curr),
  ext_number: Yup.number()
    .required(i18n.t("VALIDATION.REQUIRED")),
  postal_code: Yup.string()
    .nullable()
    .min(5, "Código postal no válido")
    .max(5, "Código postal no válido")
    .transform((curr, orig) => orig === '' ? null : curr)
    .required(i18n.t("VALIDATION.REQUIRED")),
  postal_code_id: Yup.string()
    .required(i18n.t("VALIDATION.REQUIRED")),
  state_id: Yup.string()
    .required(i18n.t("VALIDATION.REQUIRED")),
  municipality_id: Yup.string()
    .required(i18n.t("VALIDATION.REQUIRED")),
  neighborhood_id: Yup.string()
    .required(i18n.t("VALIDATION.REQUIRED")),

  contact_name: Yup.string()
    .required(i18n.t("VALIDATION.REQUIRED")),
  contact_last_name: Yup.string()
    .required(i18n.t("VALIDATION.REQUIRED")),
  contact_second_last_name: Yup.string(),
  contact_phone: Yup.string()
    .min(10, "Colocar un número telefónico válido")
    .max(10, "Colocar un número telefónico válido")
    .transform((curr, orig) => orig === '' ? null : curr)
    .required(i18n.t("VALIDATION.REQUIRED")),

  tutor_name: Yup.string()
    .when("licence_type_id", (licenceTypeId) => {
      if (licenceTypeId == 6 || licenceTypeId == 7) {
        return Yup.string().required(i18n.t("VALIDATION.REQUIRED"))
      }
    }),
  tutor_last_name: Yup.string()
    .when("licence_type_id", (licenceTypeId) => {
      if (licenceTypeId == 6 || licenceTypeId == 7) {
        return Yup.string().required(i18n.t("VALIDATION.REQUIRED"))
      }
    }),
  tutor_second_last_name: Yup.string()
    .when("licence_type_id", (licenceTypeId) => {
      if (licenceTypeId == 6 || licenceTypeId == 7) {
        return Yup.string()
      }
    }),
  tutor_phone: Yup.string()
    .nullable()
    .transform((curr, orig) => orig === '' ? null : curr)
    .when("licence_type_id", (licenceTypeId) => {
      if (licenceTypeId == 6 || licenceTypeId == 7) {
        return Yup.string()
          .min(10, "Colocar un número telefónico válido")
          .max(10, "Colocar un número telefónico válido")
          .transform((curr, orig) => orig === '' ? null : curr)
          .required(i18n.t("VALIDATION.REQUIRED"))
      }
    }),

    
  tutor_curp: Yup.string()
    .when("licence_type_id", (licenceTypeId) => {
      if (licenceTypeId == 6 || licenceTypeId == 7) {
        return Yup.string()
        .min(18, "CURP no válida")
        .max(18, "CURP no válida")
        .required(i18n.t("VALIDATION.REQUIRED"))
      }
    }),
  tutor_email: Yup.string()
    .when("licence_type_id", (licenceTypeId) => {
      if (licenceTypeId == 6 || licenceTypeId == 7) {
        return Yup.string()
        .email(i18n.t('VALIDATION.EMAIL'))
        .required(i18n.t("VALIDATION.REQUIRED"))
      }
    }),
  tutor_rfc: Yup.string()
    .when("licence_type_id", (licenceTypeId) => {
      if (licenceTypeId == 6 || licenceTypeId == 7) {
        return Yup.string()
        .required(i18n.t("VALIDATION.REQUIRED"))
      .min(10, "Colocar RFC válido")
      .max(13, "Colocar RFC válido")
      }
    }),
  tutor_birthdate: Yup.string()
    .nullable()
    .when("licence_type_id", (licenceTypeId) => {
      if (licenceTypeId == 6 || licenceTypeId == 7) {
        return Yup.string()
        .typeError('Fecha no válida')
        .nullable()
        .transform((curr, orig) => orig === '' ? null : curr)
        .required(i18n.t("VALIDATION.REQUIRED"))
      }
    }),
    
  tutor_prefix: Yup.string()
  .when("licence_type_id", (licenceTypeId) => {
    if (licenceTypeId == 6 || licenceTypeId == 7) {
      return Yup.string()
      .required(i18n.t("VALIDATION.REQUIRED"))
    }
  }),
  tutor_street: Yup.string()
  .when("licence_type_id", (licenceTypeId) => {
    if (licenceTypeId == 6 || licenceTypeId == 7) {
      return Yup.string()
      .required(i18n.t("VALIDATION.REQUIRED"))
    }
  }),
  tutor_int_number: Yup.string()
  .when("licence_type_id", (licenceTypeId) => {
    if (licenceTypeId == 6 || licenceTypeId == 7) {
      return Yup.string()
      .nullable()    
      .transform((curr, orig) => orig === '' ? null : curr)
    }
  }),
    
  tutor_ext_number: Yup.string()
  .nullable()
  .when("licence_type_id", (licenceTypeId) => {
    if (licenceTypeId == 6 || licenceTypeId == 7) {
      return Yup.string()
      .nullable()
      .transform((curr, orig) => orig === '' ? null : curr)
      .required(i18n.t("VALIDATION.REQUIRED"))
    }
  }),
  tutor_postal_code: Yup.string()
  .nullable()
  .when("licence_type_id", (licenceTypeId) => {
    if (licenceTypeId == 6 || licenceTypeId == 7) {
      return Yup.string()
      .nullable()
      .min(5, "Código postal no válido")
      .max(5, "Código postal no válido")
      .transform((curr, orig) => orig === '' ? null : curr)
      .required(i18n.t("VALIDATION.REQUIRED"))
    }
  }),
    
  tutor_postal_code_id: Yup.string()
  .when("licence_type_id", (licenceTypeId) => {
    if (licenceTypeId == 6 || licenceTypeId == 7) {
      return Yup.string()
      .required(i18n.t("VALIDATION.REQUIRED"))
    }
  }),
  tutor_state_id: Yup.string()
  .when("licence_type_id", (licenceTypeId) => {
    if (licenceTypeId == 6 || licenceTypeId == 7) {
      return Yup.string()
      .required(i18n.t("VALIDATION.REQUIRED"))
    }
  }),
  tutor_municipality_id: Yup.string()
  .when("licence_type_id", (licenceTypeId) => {
    if (licenceTypeId == 6 || licenceTypeId == 7) {
      return Yup.string()
      .required(i18n.t("VALIDATION.REQUIRED"))
    }
  }),
  tutor_neighborhood_id: Yup.string()
  .when("licence_type_id", (licenceTypeId) => {
    if (licenceTypeId == 6 || licenceTypeId == 7) {
      return Yup.string()
      .required(i18n.t("VALIDATION.REQUIRED"))
    }
  }),
  tutor_sex: Yup.string()
  .when("licence_type_id", (licenceTypeId) => {
    if (licenceTypeId == 6 || licenceTypeId == 7) {
      return Yup.string()
      .required(i18n.t("VALIDATION.REQUIRED"))
    }
  }),
  tutor_marital_status: Yup.string()
  .when("licence_type_id", (licenceTypeId) => {
    if (licenceTypeId == 6 || licenceTypeId == 7) {
      return Yup.string()
      .required(i18n.t("VALIDATION.REQUIRED"))
    }
  }),
  tutor_nationality_id: Yup.string()
  .when("licence_type_id", (licenceTypeId) => {
    if (licenceTypeId == 6 || licenceTypeId == 7) {
      return Yup.string()
      .required(i18n.t("VALIDATION.REQUIRED"))
    }
  }),

  fm3: Yup.string()
    .when("nationality_id", (nationality_id) => {
      if (nationality_id != 1) {
        return Yup.string()
          .required(i18n.t("VALIDATION.REQUIRED"))
      }
    }),
});


const defaultFormData = {
  id: '',
  formality_name: '',
  formality_id: '',
  licence_type_id: '',
  module_id: '',
  appointment_date: '',
  appointment_time: '',

  licences_year_id: '',

  curp: '',
  name: '',
  last_name: '',
  second_last_name: '',
  sex: '',
  rfc: '',
  birthdate: '',
  marital_status: '',
  nationality_id: '1',
  phone: '',
  email: '',
  occupation: '',

  prefix: '',
  street: '',
  int_number: '',
  ext_number: '',
  postal_code: '',
  postal_code_id: '',
  state_id: '',
  municipality_id: '',
  neighborhood_id: '',

  contact_name: '',
  contact_last_name: '',
  contact_second_last_name: '',
  contact_phone: '',

  tutor_name: '',
  tutor_last_name: '',
  tutor_second_last_name: '',
  tutor_phone: '',
    /* ----------------- */
  tutor_email: '',
  tutor_curp: '',
  tutor_sex: '',
  tutor_rfc: '',
  tutor_birthdate: '',
  tutor_marital_status: '',
  tutor_nationality_id: '1',
  tutor_occupation: '',
  tutor_prefix: '',
  tutor_street: '',
  tutor_int_number: '',
  tutor_ext_number: '',
  tutor_postal_code_id: '',
  tutor_state_id: '',
  tutor_municipality_id: '',
  tutor_neighborhood_id: '',
  
  fm3: ''
};

function Process() {
  //aqui
  const countdown = useRef(Date.now() + 900000);
  const countdownComponent = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const { watch, formState: { errors }, control, setValue, getValues, trigger } = useForm({
    mode: 'all',
    defaultValues: defaultFormData,
    resolver: yupResolver(validationSchema),
  });
  const watchFormalityId = watch("formality_id");
  const watchLicenceTypeId = watch("licence_type_id");
  const watchModuleId = watch("module_id");
  const [selectedCalendarDate, setSelectedCalendarDate] = useState(null);
  const [selectedBirthdate, setSelectedBirthdate] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(moment().utc().month() + 1);
  const [selectedYear, setSelectedYear] = useState(moment().utc().year());
  const [amount, setAmount] = useState('');
  const [age, setAge] = useState(' ');
  const [showTimer, setShowTimer] = useState(false);

  const [selectsData, setSelectsData] = useState({
    modules: [],
    licenceTypes: [],
    selectedLicenceYears: [],
    systemAddresses: [],
    states: [],
    municipalities: [],
    neighborhoods: [],
    calendarDates: [],
    availableHours: [],
    unavailableDays: [],
    nationalities: []
  });

  useEffect(() => {
    setValue('formality_id', searchParams.get('formality_id'));
    http.get(ROUTES.SELECTS_DATA)
      .then((data: any) => {
        setSelectsData({
          ...selectsData,
          modules: data.modules,
          licenceTypes: data.licenceTypes,
          systemAddresses: data.systemAddresses,
          nationalities: data.nationalities
        })
      })
      .catch(error => {
        enqueueSnackbar(
          error.message != '' ? error.message :
            'Hubo un error al obtener los datos necesarios para el formulario.\n' + error.exception,
          { variant: 'error' });
      });
  }, []);

  useEffect(() => {
    if (watchLicenceTypeId) {
      let selectedLicenceType = selectsData.licenceTypes
        .find(licenceType => (licenceType.id + "") == watchLicenceTypeId);
      setSelectsData({ ...selectsData, selectedLicenceYears: selectedLicenceType.years });
    }
    if (watchLicenceTypeId && watchModuleId) {
      http.post(ROUTES.CALENDAR_AVAILABLE_DATES, {
        year: selectedYear,
        month: selectedMonth,
        module_id: watchModuleId,
        licence_type_id: watchLicenceTypeId,
        formality_id: searchParams.get('formality_id')
      })
        .then((calendarAvailableHours: any) => {
          let unavailableDays = [];
          Object.keys(calendarAvailableHours).forEach(day => {
            if (!calendarAvailableHours[day].length) {
              unavailableDays.push(day);
            }
          });
          setValue('appointment_date', '');
          setValue('appointment_time', '');
          setSelectedCalendarDate(null);
          setSelectsData({ ...selectsData, calendarDates: calendarAvailableHours, unavailableDays, availableHours: [] })
        })
        .catch(error => {
          enqueueSnackbar(
            error.message != '' ? error.message :
              'Hubo un error al obtener los datos necesarios para el formulario.\n' + error.exception,
            { variant: 'error' });
        });
    }
  }, [watchLicenceTypeId, watchModuleId, selectedMonth, selectedYear]);

  return (
    <>
      <Helmet>
        <title>Proceso</title>
      </Helmet>

      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={6} md={6}>
            <Box style={{ marginTop: 20 }}>
              <img src={require('../../assets/img/logo.png')} width="30%" />
            </Box>
          </Grid>
          <Grid item xs={6} md={6} sx={{ alignSelf: "center" }} style={showTimer ? {} : { display: 'none' }}>
            <Box style={{ marginTop: 20, textAlign: "right" }}>
              <Typography variant="h4" sx={{ pb: 1, pt: 0 }}>
                Tiempo restante para agendar cita:
              </Typography>
              <Chip color="error" icon={<TimerIcon />} label={<Countdown ref={countdownComponent} autoStart={false} date={countdown.current} renderer={renderer} />} sx={{ fontWeight: "bold" }} />
            </Box>
          </Grid>
        </Grid>
        <Divider style={{ marginTop: 10, marginBottom: 5 }}></Divider>
      </Container>

      <Container maxWidth="xl" sx={{ mb: 10 }}>

        <form noValidate>
          <Stepper control={control} generalDataController={{
            selectsData,
            setSelectsData,
            selectedMonth,
            setSelectedMonth,
            selectedYear,
            setSelectedYear,
            watchLicenceTypeId,
            watchModuleId,
            selectedCalendarDate,
            setSelectedCalendarDate,
            amount,
            setAmount,
            age, 
            setAge,
            showTimer,
            setShowTimer,
            setValue,
            getValues,
            errors,
            trigger,
            enqueueSnackbar,
            watchFormalityId,
            selectedBirthdate,
            setSelectedBirthdate,
            countdownComponent
          }} />
        </form>
      </Container>
    </>
  );
}

const styles = ({
  mainContainer: {
    flex: 1,
    backgroundColor: 'transparent',
    resizeMode: 'cover',
    justifyContent: 'center',
    alignItems: 'center',
  }
});

export default Process;
