import CrudBase from "src/components/CrudBase";
import { useTranslation } from "react-i18next";
import AddressVerificationDocumentsForms from "./AddressVerificationDocumentsForm";
import FileOpenIcon from '@mui/icons-material/FileOpen';

export function AddressVerificationDocuments() {
    const { t } = useTranslation();

    return (
        <CrudBase
            resource="address-verification-documents"
            indexColumns={['name']}
            form={AddressVerificationDocumentsForms}
            searchText={true}
            deleteButton={false}
            icon={<FileOpenIcon fontSize="large" />}
        />
    );
}
