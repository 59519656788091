import CrudBase from "src/components/CrudBase";
import { useTranslation } from "react-i18next";
import FormalitiesForm from "./FormalitiesForm";
import SummarizeIcon from '@mui/icons-material/Summarize';

export function Formalities() {
    const { t } = useTranslation();

    return (
        <CrudBase
            resource="formalities"
            indexColumns={['name']}
            form={FormalitiesForm}
            searchText={true}
            deleteButton={false}
            icon={<SummarizeIcon fontSize="large" />}
        />
    );
}
