import CrudBase from "src/components/CrudBase";
import { useTranslation } from "react-i18next";
import LicenceTypesForm from "./LicenceTypesForm";
import CreditCardIcon from '@mui/icons-material/CreditCard';

export function LicenceTypes() {
    const { t } = useTranslation();

    return (
        <CrudBase
            resource="licence-types"
            indexColumns={['name']}
            form={LicenceTypesForm}
            searchText={true}
            deleteButton={false}
            icon={<CreditCardIcon fontSize="large" />}
        />
    );
}
