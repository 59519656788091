import React from "react";
import DescriptionIcon from '@mui/icons-material/Description';
import { Grid, Typography, ListItemText, List, ListItem, ListItemIcon } from "@mui/material";

function Block6() {
    return (
        <>
            <Grid item sm={8} sx={{ textAlign: "center", backgroundColor: "#283A63", py: 1, color: "#fff" }}>
                <Typography variant="h1">
                    Licencia de conducir extranjeros
                </Typography>
                <Typography sx={{ mb: 1 }} variant="h3" style={{ fontWeight: 400, color: "#fff" }}>
                    Trámite de licencia de conducir para extranjeros.
                </Typography>
            </Grid>

            <Grid item sm={12} sx={{ mb: 0 , mt:2 }}>
                <Typography variant="h3" style={{ fontWeight: 500 }}>
                    El contribuyente extranjero deberá presentar los siguientes requisitos en original:
                </Typography>

                <List sx={{ width: '100%' }}>
                    <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{ minWidth: 40, marginTop: 0 }}>
                            <DescriptionIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="h4"
                                        color="text.primary"
                                    >
                                        Pasaporte
                                    </Typography>
                                </React.Fragment>}
                        />
                    </ListItem>

                    <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{ minWidth: 40, marginTop: 0 }}>
                            <DescriptionIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="h4"
                                        color="text.primary"
                                    >
                                        FM2 (permiso permanente) o FM3 (permiso temporal), según sea el caso.
                                    </Typography>
                                </React.Fragment>}
                        />
                    </ListItem>

                    <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{ minWidth: 40, marginTop: 0 }}>
                            <DescriptionIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="h4"
                                        color="text.primary"
                                    >
                                        Acreditar validación Teórico Practico de Educación Vial en la localidad.

                                    </Typography>
                                </React.Fragment>}
                        />
                    </ListItem>

                    <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{ minWidth: 40, marginTop: 0 }}>
                            <DescriptionIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="h4"
                                        color="text.primary"
                                    >
                                        Debe realizar su tramite en la opcion de "Primera Vez"
                                    </Typography>
                                </React.Fragment>}
                        />
                    </ListItem>

                    <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{ minWidth: 40, marginTop: 0 }}>
                            <DescriptionIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="h4"
                                        color="text.primary"
                                    >
                                        Comprobante de domicilio
                                    </Typography>
                                </React.Fragment>}
                            secondary={
                                <React.Fragment>
                                    {<Typography>Fecha reciente (2 meses) con la misma dirección que su identificación, en caso de no contar con el mismo
                                        domicilio deberá presentar comprobante a su nombre, por ejemplo: agua, luz, teléfono, gas, predial.
                                        Si el comprobante no esta  a su nombre se deberá anexar a este como: estados de cuenta bancaria, tiendas
                                        departamentales o contar con INE actualizado.
                                    </Typography>}
                                </React.Fragment>
                            }
                        />
                    </ListItem>

                    <ListItem alignItems="flex-start">
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="h4"
                                        color="text.primary"
                                    >
                                        *Nota: Si la vigencia de la licencia de conducir era de 6 años al momento de realizar el pago,
                                        se le podrá realizar un descuento de acuerdo a años completos que le quedaran de vigencia.
                                    </Typography>
                                </React.Fragment>}
                        />
                    </ListItem>

                </List>
            </Grid>

            <Grid container rowSpacing={2}>
                <Grid item sm={12}>
                    <Typography sx={{ fontSize: "15px" }}>
                        <span style={{ color: "#CE0C6A", fontWeight: "bold" }}>Automovilista particular: </span>
                        Al tener la licencia de conducir, le autoriza a conducir vehículos particulares hasta de dos plazas,
                        o de carga particular cuyo peso máximo autorizado no exceda de 3.5 toneladas.
                    </Typography>
                </Grid>

                <Grid item sm={12}>
                    <Typography sx={{ fontSize: "15px" }}>
                        <span style={{ color: "#CE0C6A", fontWeight: "bold" }}>Chofer particular: </span>
                        Al tener la licencia, le autoriza a conducir vehículos particulares con peso máximo de 10 toneladas; además ampara también la conducción de los vehículos que requieren licencia de automovilista particular.
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
}
export default Block6;