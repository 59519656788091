import CrudBase from "src/components/CrudBase";
import { useTranslation } from "react-i18next";
import NationalitiesForm from "./NationalitiesForm";
import PublicIcon from '@mui/icons-material/Public';

export function Nationalities() {
    const { t } = useTranslation();

    return (
        <CrudBase
            resource="nationalities"
            indexColumns={['name']}
            form={NationalitiesForm}
            searchText={true}
            deleteButton={false}
            icon={<PublicIcon fontSize="large" />}
        />
    );
}
