import { FC, ReactNode, createContext, useEffect, useReducer } from "react";
import { User } from "src/models/user";
import http, { setUnauthenticatedCallback } from "src/utils/httpHelper";
import SnackbarUtilsConfigurator from "src/utils/SnackbarUtilsConfigurator";

interface AuthState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

interface AuthContextValue extends AuthState {
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  register: (username: string, email: string, password: string, password_confirmation) => Promise<void>;
  setUser: (user: User) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: "INITIALIZE";
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: "LOGIN";
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: "LOGOUT";
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type RegisterAction = {
  type: "REGISTER";
  payload: {
    user: User;
  };
};

type setUser = {
  type: "SET_USER";
  payload: {
    user: User;
  };
};

type Action = InitializeAction | LoginAction | LogoutAction | RegisterAction | setUser;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers: Record<
  string,
  (state: AuthState, action: Action) => AuthState
> = {
  INITIALIZE: (state: AuthState, action: InitializeAction): AuthState => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state: AuthState, action: LoginAction): AuthState => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state: AuthState): AuthState => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state: AuthState, action: RegisterAction): AuthState => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  SET_USER: (state: AuthState, action: RegisterAction): AuthState => {
    const { user } = action.payload;

    return {
      ...state,
      user,
    };
  },
};

const reducer = (state: AuthState, action: Action): AuthState =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  setUser: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        await http.get("sanctum/csrf-cookie").catch((error) => {
          return Promise.reject('Hubo un problema al obtener csrf-cookie')
        });

        const isAuthenticated = await http.get("/check-authentication");
        if (isAuthenticated) {
          const user = new User(await http.get<User>("/api/user"));
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (error) {
        console.error(error);
        SnackbarUtilsConfigurator.error(error.message || error);
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();

    setUnauthenticatedCallback(() => {
      dispatch({
        type: "LOGOUT",
        payload: {
          isAuthenticated: false,
          user: null
        },
      });
    });
  }, []);

  const login = async (email: string, password: string, remember = false): Promise<void> => {
    try {
      const user = new User(await http.post<User>("/login", {
        email,
        password,
        remember
      }));

      dispatch({
        type: "LOGIN",
        payload: {
          user,
        },
      });
    } catch (error) {
      console.error(error);
      SnackbarUtilsConfigurator.error(error.message);
    }
  };

  const setUser = async (user): Promise<void> => {
    dispatch({
      type: "SET_USER",
      payload: {
        user,
      },
    });
  };

  const logout = async (): Promise<void> => {
    try {
      await http.post("/logout");
      dispatch({
        type: "LOGOUT",
        payload: {
          isAuthenticated: false,
          user: null,
        }
      });
    } catch (error) {
      console.error(error);
      SnackbarUtilsConfigurator.error(error.message);
    }
  };

  const register = async (
    username: string,
    email: string,
    password: string,
    password_confirmation: string
  ): Promise<void> => {
    try {
      const user = new User(await http.post<User>(
        "/register",
        {
          username,
          email,
          password,
          password_confirmation
        }
      ));

      dispatch({
        type: "REGISTER",
        payload: {
          user
        },
      });
    } catch (error) {
      console.error(error);
      SnackbarUtilsConfigurator.error(error.message);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        register,
        setUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
