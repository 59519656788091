import CrudBase from "src/components/CrudBase";
import { useTranslation } from "react-i18next";
import ModulesForm from "./ModulesForm";
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';

export function Modules() {
    const { t } = useTranslation();

    return (
        <CrudBase
            resource="modules"
            indexColumns={['name', 'municipality']}
            customColumns={
                {
                    'municipality': (municipality) => municipality.name
                }
            }
            showButton={false}
            form={ModulesForm}
            searchText={true}
            deleteButton={false}
            icon={<MeetingRoomIcon fontSize="large" />}
        />
    );
}
