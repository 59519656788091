import React from "react";
import DescriptionIcon from '@mui/icons-material/Description';
import { Grid, Typography, ListItemText, List, ListItem, ListItemIcon } from "@mui/material";

function Block8() {
    return (
        <>
            <Grid item sm={8} sx={{ textAlign: "center", backgroundColor: "#283A63", py: 1, color: "#fff" }}>
                <Typography variant="h1">
                    Licencia de transporte público
                </Typography>
                <Typography sx={{ mb: 1 }} variant="h3" style={{ fontWeight: 400, color: "#fff" }}>
                    Trámite de licencia para transporte público.
                </Typography>
            </Grid>

            <Grid item sm={12} sx={{ mb: 2 , mt:2 }}>
                <Typography variant="h3" style={{ fontWeight: 500 }}>
                    El contribuyente deberá acudir a la Dirección de Transporte ubicada en el edificio de Delegación de la Policía Vial,
                    donde se le pedirán los requisitos necesarios para que se pueda expedir la licencia de este tipo. Después deberá
                    presentar el expediente que se le entregará para iniciar su trámite en el Departamento de Licencia.
                </Typography>
            </Grid>
        </>
    );
}
export default Block8;