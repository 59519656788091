import CrudBase from "src/components/CrudBase";
import { useTranslation } from "react-i18next";
import IdentificationsForm from "./IdentificationsForm";
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';

export function Identifications() {
    const { t } = useTranslation();

    return (
        <CrudBase
            resource="identifications"
            indexColumns={['name']}
            form={IdentificationsForm}
            searchText={true}
            deleteButton={false}
            icon={<ContactEmergencyIcon fontSize="large" />}
        />
    );
}
