import axios from 'axios';
import { parse } from "content-disposition-header";
import { customHistory } from 'src/components/CustomRouter';
import i18n from 'src/i18n/i18n';

const ROUTES = {
    //BASE: 'http://localhost:8000',
    //BASE: 'https://citassspe.chihuahua.gob.mx',
    BASE: 'https://api.citas.sokodev.com',
    CONFIGURATION_ADDRESSES_UPLOAD: '/api/configurations-addresses',
    LICENCE_TYPES_RESOURCE: '/api/licence-types',
    MODULES_RESOURCE: '/api/modules',
    FORMALITIES_RESOURCE: '/api/formalities',
    IDENTIFICATIONS_RESOURCE: '/api/identifications',
    ADDRESS_VERIFICATION_DOCUMENTS_RESOURCE: '/api/address-verification-documents',
    NATIONALITIES_RESOURCE: '/api/nationalities',

    MUNICIPALITIES_FOR_SELECT: '/api/municipalities-for-select',
    SELECTS_DATA: '/api/appointments-form-selects-data',
    CALENDAR_AVAILABLE_DATES: '/api/appointment/calendar',
    GENERATE_APPOINTMENT: '/api/appointment',
    CANCEL_APPOINTMENT: '/api/appointment/cancel'
}

let onUnauthenticatedCallback;

const http = axios.create({
    baseURL: ROUTES.BASE,
    headers: {
        "X-requested-With": "XMLHttpRequest",
    },
    withCredentials: true,
});

http.interceptors.response.use(
    (response) => Promise.resolve(response.data),
    (error) => {
        if (axios.isCancel(error)) {
            return Promise.reject(error);
        }

        switch (error.response.status) {
            case 403:
                if (error.response.data.exception !== 'Illuminate\\Routing\\Exceptions\\InvalidSignatureException') {
                    customHistory.push('/status/403');
                }
                break;

            case 401:
                onUnauthenticatedCallback();
                throw new axios.Cancel(i18n.t('STATUS.401.MESSAGE'));

            default:
                break;
        }
        return Promise.reject((error.response && error.response.data) || error);
    }
);

const downloadFile = (url) => {
    http.get(url, { responseType: 'blob' }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let filename = 'unknown';
        if (response.headers['content-disposition']) {
            const contentDisposition = parse(response.headers['content-disposition']);
            filename = contentDisposition.parameters.filename ?? filename;
        }
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
    });
}

const setUnauthenticatedCallback = (onUnauthenticatedCallbackFn: Function) => {
    onUnauthenticatedCallback = onUnauthenticatedCallbackFn;
}

const rebuildData = (values, edit = false) => {
    let formData = new FormData();
    Object.keys(values).forEach(key => {
        if (values[key]) {
            if (Array.isArray(values[key])) {
                values[key].forEach(element => {
                    formData.append(key + '[]', element);
                });
            } else {
                formData.append(key, values[key]);
            }
        }
    });
    if (edit) {
        formData.append('_method', 'PUT');
    }
    return formData;
};

export default http;
export { downloadFile, ROUTES, setUnauthenticatedCallback, rebuildData };
