import React, { FC, PropsWithChildren } from "react";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { createBrowserHistory } from "history";

const customHistory = createBrowserHistory({ window });

const CustomRouter: FC<PropsWithChildren> = ({ children, ...props }) => {
    return (
        <HistoryRouter history={customHistory} {...props}>
            {children}
        </HistoryRouter>
    );
};

export default CustomRouter;
export {
    customHistory
}