import PageHeader from "src/components/PageHeader";
import Grid from '@mui/material/Unstable_Grid2';
import {
    Box,
    Typography,
    Divider,
    Avatar,
    List,
    ListItem,
    ListItemText,
    Alert,
    styled,
    Button,
    CircularProgress
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import SettingsIcon from '@mui/icons-material/Settings';
import http, { rebuildData, ROUTES } from "src/utils/httpHelper";
import { useSnackbar } from "notistack";
import { useState } from "react";

const UploadBox = styled(Box)(
    ({ theme }) => `
      border-radius: ${theme.general.borderRadius};
      padding: ${theme.spacing(2)};
      background: ${theme.colors.secondary.lighter};
  `
);

const BoxUploadWrapper = styled(Box)(
    ({ theme }) => `
      border-radius: ${theme.general.borderRadius};
      padding: ${theme.spacing(2)};
      margin-top: ${theme.spacing(2)};
      background: ${theme.colors.secondary.lighter};
      border: 1px dashed ${theme.colors.secondary.main};
      outline: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: ${theme.transitions.create(['border', 'background'])};
  
      &:hover {
        background: ${theme.colors.secondary.light};
        border-color: ${theme.colors.secondary.dark};
      }
  `
);

const DividerContrast = styled(Divider)(
    ({ theme }) => `
      background: ${theme.colors.primary.main};
    `
);

const AvatarWrapper = styled(Avatar)(
    ({ theme }) => `
      background: ${theme.colors.primary.main};
      width: ${theme.spacing(7)};
      height: ${theme.spacing(7)};
  `
);

const AvatarSuccess = styled(Avatar)(
    ({ theme }) => `
      background: ${theme.colors.success.light};
      width: ${theme.spacing(7)};
      height: ${theme.spacing(7)};
  `
);

const AvatarDanger = styled(Avatar)(
    ({ theme }) => `
      background: ${theme.colors.error.light};
      width: ${theme.spacing(7)};
      height: ${theme.spacing(7)};
  `
);

function Settings() {
    const { t }: { t: any } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);
    const {
        acceptedFiles,
        isDragActive,
        isDragAccept,
        isDragReject,
        getRootProps,
        getInputProps,
        inputRef
    } = useDropzone({
        accept: {
            'application/vnd.ms-excel': ['.xls'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
        }

    });

    const files = acceptedFiles.map((file, index) => (
        <ListItem disableGutters component="div" key={index}>
            <ListItemText primary={file.name} />
            <b>{file.size} bytes</b>
            <DividerContrast />
        </ListItem>
    ));

    const handleUpload = () => {
        setIsLoading(true);
        http.post(ROUTES.CONFIGURATION_ADDRESSES_UPLOAD, rebuildData({ system_addreses: acceptedFiles[0] }))
            .then((response) => {
                enqueueSnackbar('Se han guardado los datos.', { variant: 'success' })
            })
            .catch(error => {
                enqueueSnackbar(
                    error.message != '' ? error.message :
                        'Hubo un error al guardar los datos.\n' + error.exception,
                    { variant: 'error' });
            })
            .finally(() => {
                setIsLoading(false);
            })
    };

    return (
        <>
            <PageHeader heading={t('SETTINGS.HEADING')} subheading={t('SETTINGS.SUBHEADING')} icon={<SettingsIcon fontSize="large" />} />
            <Grid
                container
                sx={{
                    px: 4
                }}>
                <Grid lg={12}>
                    <UploadBox>
                        <Typography variant="h4" gutterBottom>
                            {t('SETTINGS.SYSTEM_ADDRESSES_UPLOAD')}
                        </Typography>
                        <Typography variant="body1">
                            {t('SETTINGS.SYSTEM_ADDRESSES_UPLOAD_DESC')}
                            <a href="https://www.correosdemexico.gob.mx/SSLServicios/ConsultaCP/CodigoPostal_Exportar.aspx" target={'_blank'}>{t('SETTINGS.ADDRESSES')}</a>
                        </Typography>

                        <BoxUploadWrapper {...getRootProps()}>
                            <input {...getInputProps()} />
                            {isDragAccept && (
                                <>
                                    <AvatarSuccess variant="rounded">
                                        <CheckTwoToneIcon />
                                    </AvatarSuccess>
                                    <Typography
                                        sx={{
                                            mt: 2
                                        }}
                                    >
                                        {t('Drop the files to start uploading')}
                                    </Typography>
                                </>
                            )}
                            {isDragReject && (
                                <>
                                    <AvatarDanger variant="rounded">
                                        <CloseTwoToneIcon />
                                    </AvatarDanger>
                                    <Typography
                                        sx={{
                                            mt: 2
                                        }}
                                    >
                                        {t('You cannot upload these file types')}
                                    </Typography>
                                </>
                            )}
                            {!isDragActive && (
                                <>
                                    <AvatarWrapper variant="rounded">
                                        <CloudUploadTwoToneIcon />
                                    </AvatarWrapper>
                                    <Typography
                                        sx={{
                                            mt: 2
                                        }}
                                    >
                                        {t('Drag & drop files here')}
                                    </Typography>
                                </>
                            )}
                        </BoxUploadWrapper>
                        {files.length > 0 && (
                            <>
                                {/* <Alert
                                    sx={{
                                        py: 0,
                                        mt: 2
                                    }}
                                    severity="success"
                                >
                                    {t('You have uploaded')} <b>{files.length}</b> {t('files')}!
                                </Alert> */}
                                <DividerContrast
                                    sx={{
                                        mt: 2
                                    }}
                                />
                                <List disablePadding component="div">
                                    {files}
                                </List>
                            </>
                        )}
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                sx={{
                                    mt: 2,

                                }}
                                type="submit"
                                variant="contained"
                                onClick={handleUpload}
                                disabled={isLoading}
                                startIcon={
                                    isLoading ? <CircularProgress size="1rem" /> : null
                                }
                            >
                                {t("SETTINGS.SYSTEM_ADDRESSES_SUBMIT")}
                            </Button>
                        </Box>
                    </UploadBox>
                </Grid>
            </Grid>
        </>
    );
}

export default Settings;
