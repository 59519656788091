import { useRoutes } from "react-router-dom";
import router from "src/router";

import { SnackbarProvider } from "notistack";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import useAuth from "src/hooks/useAuth";

import { CssBaseline } from "@mui/material";
import AppInit from "./components/AppInit";
import ThemeProviderWrapper from "./theme/ThemeProvider";
import { SnackbarUtilsConfigurator } from "./utils/SnackbarUtilsConfigurator";
import esLocale from 'date-fns/locale/es';

function App() {
  const content = useRoutes(router);
  const auth = useAuth();

  return (
    <ThemeProviderWrapper>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
        <SnackbarProvider
          maxSnack={6}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <CssBaseline />
          {auth.isInitialized ? content : <AppInit />}
          <SnackbarUtilsConfigurator />
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProviderWrapper>
  );
}
export default App;
