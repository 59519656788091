import Authenticated from "src/components/Authenticated";
import BaseLayout from "src/layouts/BaseLayout";

//Icons
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";

//Routes and Elements
import baseRoutes from "./base";
import { Users } from "src/pages/Users";
import AccentHeaderLayout from "src/layouts/AccentHeaderLayout";
import BottomNavigationLayout from "src/layouts/BottomNavigationLayout";
import AccentSidebarLayout from "src/layouts/AccentSidebarLayout";
import BoxedSidebarLayout from "src/layouts/BoxedSidebarLayout";
import CollapsedSidebarLayout from "src/layouts/CollapsedSidebarLayout";
import ExtendedSidebarLayout from "src/layouts/ExtendedSidebarLayout";
import TopNavigationLayout from "src/layouts/TopNavigationLayout";
import Dashboard from "src/pages/Dashboard";
import MyAccount from "src/pages/MyAccount";
import { Roles } from "src/pages/Roles";
import { Navigate } from "react-router-dom";
import Settings from "src/pages/Settings";
import { LicenceTypes } from "src/pages/LicenceTypes";
import { Modules } from "src/pages/Modules";
import { Formalities } from "src/pages/Formalities";
import { Identifications } from "src/pages/Identifications";
import { AddressVerificationDocuments } from "src/pages/AddressVerificationDocuments";
import { Nationalities } from "src/pages/Nationalities";

let layout = <ExtendedSidebarLayout />;
switch (localStorage.getItem("layout")) {
  case "EXTENDED_SIDEBAR":
    layout = <ExtendedSidebarLayout />;
    break;
  case "ACCENT_HEADER":
    layout = <AccentHeaderLayout />;
    break;
  case "ACCENT_SIDEBAR":
    layout = <AccentSidebarLayout />;
    break;
  case "BOXED_SIDEBAR":
    layout = <BoxedSidebarLayout />;
    break;
  case "COLLAPSED_SIDEBAR":
    layout = <CollapsedSidebarLayout />;
    break;
  case "BOTTOM_NAVIGATION":
    layout = <BottomNavigationLayout />;
    break;
  case "TOP_NAVIGATION":
    layout = <TopNavigationLayout />;
    break;
  default:
    break;
}

const router = [
  {
    path: "/",
    element: <BaseLayout />,
    children: baseRoutes
  },

  {
    path: "admin",
    element: <Authenticated>{layout}</Authenticated>,
    children: [
      {
        path: "",
        element: <Navigate to="dashboard" replace />,
      },
      {
        path: "dashboard",
        element: <Dashboard />,
        children: [
          {
            path: "1",
            element: <Dashboard />
          },
          {
            path: "2",
            element: <Users />
          },
        ],
      },
      {
        path: 'roles',
        element: <Roles />
      },
      {
        path: "users",
        element: <Users />
      },
      {
        path: 'my-account',
        element: <MyAccount />
      },
      {
        path: 'settings',
        element: <Settings />
      },
      {
        path: 'licence-types',
        element: <LicenceTypes />
      },
      {
        path: 'modules',
        element: <Modules />
      },
      {
        path: 'formalities',
        element: <Formalities />
      },
      {
        path: 'identifications',
        element: <Identifications />
      },
      {
        path: 'address-verification-documents',
        element: <AddressVerificationDocuments />
      },
      {
        path: 'nationalities',
        element: <Nationalities />
      }
    ],
  },
];

export default router;
