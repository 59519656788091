import { FC } from "react";
import useAuth from "src/hooks/useAuth";
import { useNavigate } from "react-router-dom";

import {
    Avatar,
    Box,
    Button,
    Divider,
    MenuList,
    alpha,
    MenuItem,
    ListItemText,
    Typography,
    styled,
    useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
import ChevronRightTwoToneIcon from "@mui/icons-material/ChevronRightTwoTone";

const MenuListWrapperPrimary = styled(MenuList)(
    ({ theme }) => `
    padding: ${theme.spacing(2)};
  
    & .MuiMenuItem-root {
        border-radius: 50px;
        padding: ${theme.spacing(1, 1, 1, 2.5)};
        min-width: 200px;
        margin-bottom: 2px;
        position: relative;
        color: ${theme.colors.alpha.black[100]};
  
        &.Mui-selected,
        &:hover,
        &.MuiButtonBase-root:active {
            background: ${theme.colors.primary.lighter};
            color: ${theme.colors.primary.main};
        }
  
        &:last-child {
            margin-bottom: 0;
        }
      }
  `
);

const MenuUserBox = styled(Box)(
    ({ theme }) => `
          background: ${alpha(theme.colors.alpha.black[100], 0.08)};
          padding: ${theme.spacing(2)};
  `
);

const UserBoxText = styled(Box)(
    ({ theme }) => `
          text-align: left;
          padding-left: ${theme.spacing(1)};
  `
);

const UserBoxLabel = styled(Typography)(
    ({ theme }) => `
          font-weight: ${theme.typography.fontWeightBold};
          color: ${theme.palette.secondary.main};
          display: block;
  `
);

const UserBoxDescription = styled(Typography)(
    ({ theme }) => `
          color: ${theme.palette.secondary.light}
  `
);

interface HeaderUserboxPopoverContentProps {
    closeCallBack: any
}

const HeaderUserboxPopoverContent: FC<HeaderUserboxPopoverContentProps> = ({ closeCallBack }) => {
    const { t }: { t: any } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const { user, logout } = useAuth();

    const handleLogout = async (): Promise<void> => {
        try {
            await logout();
        } catch (err) {
            console.error(err);
        }
    };

    const navigateToMyAccount = () => {
        navigate('/admin/my-account');
        closeCallBack();
    }

    return (
        <>
            <MenuUserBox
                sx={{
                    minWidth: 210,
                }}
                display="flex"
            >
                <Avatar
                    variant="rounded"
                    alt={user.username}
                    src={"/static/images/placeholders/avatar.png"}
                />
                <UserBoxText>
                    <UserBoxLabel variant="body1">{user.username}</UserBoxLabel>
                    <UserBoxDescription>{user.role_name}</UserBoxDescription>
                </UserBoxText>
            </MenuUserBox>
            <Divider
                sx={{
                    mb: 0,
                }}
            />
            <MenuListWrapperPrimary disablePadding>
                <MenuItem onClick={navigateToMyAccount}>
                    <ListItemText
                        primaryTypographyProps={{
                            variant: "h5",
                        }}
                        primary={t("MY_ACCOUNT.HEADING")}
                    />
                    <ChevronRightTwoToneIcon
                        sx={{
                            color: `${theme.colors.alpha.black[30]}`,
                            opacity: 0.8,
                        }}
                    />
                </MenuItem>
            </MenuListWrapperPrimary>
            <Divider />
            <Box m={1}>
                <Button color="primary" fullWidth onClick={handleLogout}>
                    <LockOpenTwoToneIcon
                        sx={{
                            mr: 1,
                        }}
                    />
                    {t("GENERAL.LOGOUT")}
                </Button>
            </Box>
        </>
    )
}

export default HeaderUserboxPopoverContent;