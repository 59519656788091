import { FC, useContext, useState, MouseEvent } from "react";
import {
  styled,
  Button,
  MenuItem,
  Menu,
  Typography,
  Stack,
  Divider,
  Box,
  Tooltip,
  Card,
  CardContent,
} from "@mui/material";
import { ThemeContext } from "src/theme/ThemeProvider";
import CheckTwoToneIcon from "@mui/icons-material/CheckTwoTone";
import { useTranslation } from "react-i18next";
import UnfoldMoreTwoToneIcon from "@mui/icons-material/UnfoldMoreTwoTone";

const ButtonWrapper = styled(Box)(
  ({ theme }) => `
        cursor: pointer;
        position: relative;
        border-radius: ${theme.general.borderRadiusXl};
        padding: ${theme.spacing(0.8)};
        display: flex;
        flex-direction: row;
        align-items: stretch;
        min-width: 80px;
        box-shadow: 0 0 0 2px ${theme.colors.primary.lighter};

        &:hover {
            box-shadow: 0 0 0 2px ${theme.colors.primary.light};
        }

        &.active {
            box-shadow: 0 0 0 2px ${theme.palette.primary.main};

            .colorSchemeWrapper {
                opacity: .6;
            }
        }
  `
);

const ColorSchemeWrapper = styled(Box)(
  ({ theme }) => `

    position: relative;

    border-radius: ${theme.general.borderRadiusXl};
    height: 28px;
    
    &.colorSchemeWrapper {
        display: flex;
        align-items: stretch;
        width: 100%;

        .primary {
            border-top-left-radius: ${theme.general.borderRadiusXl};
            border-bottom-left-radius: ${theme.general.borderRadiusXl};
        }

        .secondary {
            border-top-right-radius: ${theme.general.borderRadiusXl};
            border-bottom-right-radius: ${theme.general.borderRadiusXl};
        }

        .primary,
        .secondary,
        .alternate {
            flex: 1;
        }
    }

    &.pureLight {
        .primary {
            background: #5569ff;
        }
    
        .secondary {
            background: #f2f5f9;
        }
    }

    &.greyGoose {
        .primary {
            background: #2442AF;
        }
    
        .secondary {
            background: #F8F8F8;
        }
    }
    
    &.purpleFlow {
        .primary {
            background: #9b52e1;
        }
    
        .secondary {
            background: #00b795;
        }
    }
    
    &.nebulaFighter {
        .primary {
            background: #8C7CF0;
        }
    
        .secondary {
            background: #070C27;
        }
    }

    &.greenFields {
        .primary {
            background: #44a574;
        }
    
        .secondary {
            background: #141c23;
        }
    }

    &.darkSpaces {
        .primary {
            background: #CB3C1D;
        }
    
        .secondary {
            background: #1C1C1C;
        }
    }

  `
);

const CheckSelected = styled(Box)(
  ({ theme }) => `
    background: ${theme.palette.success.main};
    border-radius: 50px;
    height: 26px;
    width: 26px;
    color: ${theme.palette.success.contrastText};
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -13px 0 0 -13px;
    z-index: 5;

    .MuiSvgIcon-root {
        height: 16px;
        width: 16px;
    }

  `
);
interface ThemeSettingsProps {
  setting: 'layout' | 'color'
}
const ThemeSettings: FC<ThemeSettingsProps> = ({ setting }) => {
  const { t }: { t: any } = useTranslation();
  const setThemeName = useContext(ThemeContext);
  const curThemeName = localStorage.getItem("appTheme") || "PureLightTheme";
  const curLayoutName = localStorage.getItem('layout') || 'BOXED_SIDEBAR';
  const [theme, setTheme] = useState(curThemeName);
  const changeTheme = (theme: any): void => {
    setTheme(theme);
    setThemeName(theme);
  };

  const changeLayout = (layout: any): void => {
    localStorage.setItem("layout", layout);
    window.location.reload();
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const openMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  if (setting == 'layout') {
    return (
      <>
        <Card>
          <Box
            p={3}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography variant="h4" gutterBottom>
                {t('THEME_SETTINGS.ADMIN_PANEL_LAYOUT')}
              </Typography>
              <Typography variant="subtitle2">
                {t('THEME_SETTINGS.ADMIN_PANEL_LAYOUT_DESCRIPTION')}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <CardContent
            sx={{
              p: 4
            }}
          >
            <Button
              fullWidth
              size="large"
              variant="outlined"
              endIcon={<UnfoldMoreTwoToneIcon />}
              color="primary"
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={openMenu}
            >
              {t('THEME_SETTINGS.LAYOUTS.' + curLayoutName)}
            </Button>
            <Menu
              disableScrollLock
              anchorEl={anchorEl}
              open={open}
              onClose={closeMenu}
              anchorOrigin={{
                vertical: "center",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: "center",
              }}
            >
              <MenuItem
                selected={curLayoutName == 'EXTENDED_SIDEBAR'}
                sx={{
                  fontWeight: "bold",
                }}
                onClick={() => {
                  changeLayout("EXTENDED_SIDEBAR");
                }}
              >
                {t('THEME_SETTINGS.LAYOUTS.EXTENDED_SIDEBAR')}
              </MenuItem>
              <MenuItem
                selected={curLayoutName == 'ACCENT_HEADER'}
                sx={{
                  fontWeight: "bold",
                }}
                onClick={() => {
                  changeLayout("ACCENT_HEADER");
                }}
              >
                {t('THEME_SETTINGS.LAYOUTS.ACCENT_HEADER')}
              </MenuItem>
              <MenuItem
                selected={curLayoutName == 'ACCENT_SIDEBAR'}
                sx={{
                  fontWeight: "bold",
                }}
                onClick={() => {
                  changeLayout("ACCENT_SIDEBAR");
                }}
              >
                {t('THEME_SETTINGS.LAYOUTS.ACCENT_SIDEBAR')}
              </MenuItem>
              <MenuItem
                selected={curLayoutName == 'BOXED_SIDEBAR'}
                sx={{
                  fontWeight: "bold",
                }}
                onClick={() => {
                  changeLayout("BOXED_SIDEBAR");
                }}
              >
                {t('THEME_SETTINGS.LAYOUTS.BOXED_SIDEBAR')}
              </MenuItem>
              <MenuItem
                selected={curLayoutName == 'COLLAPSED_SIDEBAR'}
                sx={{
                  fontWeight: "bold",
                }}
                onClick={() => {
                  changeLayout("COLLAPSED_SIDEBAR");
                }}
              >
                {t('THEME_SETTINGS.LAYOUTS.COLLAPSED_SIDEBAR')}
              </MenuItem>
              <MenuItem
                selected={curLayoutName == 'BOTTOM_NAVIGATION'}
                sx={{
                  fontWeight: "bold",
                }}
                onClick={() => {
                  changeLayout("BOTTOM_NAVIGATION");
                }}
              >
                {t('THEME_SETTINGS.LAYOUTS.BOTTOM_NAVIGATION')}
              </MenuItem>
              <MenuItem
                selected={curLayoutName == 'TOP_NAVIGATION'}
                sx={{
                  fontWeight: "bold",
                }}
                onClick={() => {
                  changeLayout("TOP_NAVIGATION");
                }}
              >
                {t('THEME_SETTINGS.LAYOUTS.TOP_NAVIGATION')}
              </MenuItem>
            </Menu>
          </CardContent>
        </Card>
      </>
    );
  } else {
    return (
      <>
        <Card>
          <Box
            p={3}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography variant="h4" gutterBottom>
                {t('THEME_SETTINGS.ADMIN_PANEL_THEME')}
              </Typography>
              <Typography variant="subtitle2">
                {t('THEME_SETTINGS.ADMIN_PANEL_THEME_DESCRIPTION')}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <CardContent
            sx={{
              p: 5
            }}
          >
            <Stack alignItems="center" justifyContent={"center"} spacing={2} direction={"row"}>
              <Tooltip placement="top" title={t('THEME_SETTINGS.THEMES.PURE_LIGHT_THEME')} arrow>
                <ButtonWrapper
                  className={theme === "PureLightTheme" ? "active" : ""}
                  onClick={() => {
                    changeTheme("PureLightTheme");
                  }}
                >
                  {theme === "PureLightTheme" && (
                    <CheckSelected>
                      <CheckTwoToneIcon />
                    </CheckSelected>
                  )}
                  <ColorSchemeWrapper className="colorSchemeWrapper pureLight">
                    <Box className="primary" />
                    <Box className="secondary" />
                  </ColorSchemeWrapper>
                </ButtonWrapper>
              </Tooltip>
              <Tooltip placement="top" title={t('THEME_SETTINGS.THEMES.GRAY_GOOSE_THEME')} arrow>
                <ButtonWrapper
                  className={theme === "GreyGooseTheme" ? "active" : ""}
                  onClick={() => {
                    changeTheme("GreyGooseTheme");
                  }}
                >
                  {theme === "GreyGooseTheme" && (
                    <CheckSelected>
                      <CheckTwoToneIcon />
                    </CheckSelected>
                  )}
                  <ColorSchemeWrapper className="colorSchemeWrapper greyGoose">
                    <Box className="primary" />
                    <Box className="secondary" />
                  </ColorSchemeWrapper>
                </ButtonWrapper>
              </Tooltip>
              <Tooltip placement="top" title={t('THEME_SETTINGS.THEMES.PURPLE_FLOW_THEME')} arrow>
                <ButtonWrapper
                  className={theme === "PurpleFlowTheme" ? "active" : ""}
                  onClick={() => {
                    changeTheme("PurpleFlowTheme");
                  }}
                >
                  {theme === "PurpleFlowTheme" && (
                    <CheckSelected>
                      <CheckTwoToneIcon />
                    </CheckSelected>
                  )}
                  <ColorSchemeWrapper className="colorSchemeWrapper purpleFlow">
                    <Box className="primary" />
                    <Box className="secondary" />
                  </ColorSchemeWrapper>
                </ButtonWrapper>
              </Tooltip>
            </Stack>
          </CardContent>
        </Card>
      </>
    );
  }
};

export default ThemeSettings;
